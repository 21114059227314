// CSS Import
import '@/css/app.pcss';

// Add JS Loaded class to the body
document.documentElement.classList.add('js-loaded');

// App main
const main = async () => {
  const { default: vendors } = await import('./_vendors');
  await import('./_components');

  vendors();
};

// Execute async function
main()
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  .then(() => {})
  .catch((e) => console.error(e));
